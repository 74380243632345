<template>
  <div id="lock_button">
    <div
      v-on:mousedown="toggleTimer()"
      v-on:mouseup="deleteTimer()"
      class="button_content_container"
      :class="[
        hold ? 'gray_button' : 'blue_button',
        isRunning ? 'running' : '',
      ]"
      style="display: flex"
    >
      <div
        v-if="hold"
        class="button_content_container__item"
        :class="[hold && isRunning ? 'lock_hold' : 'lock_free']"
      >
        <svg
          v-if="hold && !isRunning"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="#000000"
          height="10px"
          width="15px"
          version="1.1"
          id="Layer_1"
          viewBox="0 0 330 330"
          xml:space="preserve"
        >
          <g id="XMLID_509_">
            <path
              id="XMLID_510_"
              d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85   S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15   s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25   C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
            />
          </g>
        </svg>
        <div v-else>
          <div class="circle_container">
            <div class="background">
              <div class="circle"></div>
            </div>
            <div class="content">
              <label class="text_in_circle">{{ leftTime }}</label>
            </div>
          </div>

          <!-- <div class="text_in_circle">{{ leftTime }}</div> -->
        </div>
      </div>
      <div class="button_content_container__item lock_button_content">
        <label title="Разблокировать" v-if="hold">{{ text }}</label>
        <button :title="text" @click="$emit(callback)" v-else>
          {{ text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callback: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      hold: true,
      time: 0,
      isRunning: false,
      interval: null,
      limit: 0.5,
      clickHash: 0,
    };
  },
  methods: {
    toggleTimer() {
      this.resetState();
      this.interval = setInterval(this.incrementTime, 100);
      this.isRunning = true;
      this.clickHash += 1;
    },
    deleteTimer() {
      this.resetState();
      setTimeout(() => {
        this.clickHash -= 1;
        if (!this.isRunning) {
          if (this.clickHash <= 0) {
            this.clickHash = 0;
            this.hold = true;
          }
        }
      }, 5000);
    },
    incrementTime() {
      this.time = parseFloat(this.time) + 0.11;
      if (this.time >= this.limit) {
        this.resetState();
        this.hold = false;
      }
    },
    resetState() {
      clearInterval(this.interval);
      this.isRunning = false;
      this.time = 0;
      this.interval = null;
    },
  },
  computed: {
    leftTime() {
      return Number((this.limit - this.time).toFixed(1));
    },
  },
};
</script>

<style lang="less">
@import "../../assets/styles/buttons.less";

#lock_button {
  .button_content_container {
    display: flex;
    align-items: center;
    justify-content: center;

    &.gray_button {
        // transition: 0.1s;
      &.running {
        padding: 4px 10px;
      }
    }
    &.blue_button {
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button_content_container__item {
      &.lock_hold {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #4150b7;
        margin-right: 3px;
        // transition: 0.1s;
      }
      &.lock_free {
        all: unset;
        cursor: pointer;
      }
      &.lock_button_content {
        button {
          all: unset;
          cursor: pointer;
        }
        label {
            cursor: pointer;

        }
        
      }
    }

    .circle {
      width: 2.5px;
      height: 2.5px;
      background: #4150b7;
      border-radius: 50%;
      border: 1px solid #4151b700;
      animation: circle 0.9s linear infinite;
      
    }

    @keyframes circle {
      0% {
        transform: rotate(0deg) translate(-8px) rotate(0deg);
      }
      100% {
        transform: rotate(360deg) translate(-8px) rotate(-360deg);
      }
    }
  }
}

.circle_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    position: absolute;
    top: 6px;
    left: 0px;
    
  }
  .content {
    position: relative;
    margin-top: -1px;
    cursor: pointer;
    .text_in_circle {
      font-size: 8px;
      color: #4150b7;
      margin-left: -5.8px;
    }
  }
}
</style>
